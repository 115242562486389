<template>
	<div class="view-wrapper view-list-wrapper">
		<div class="page-top-bg-addition" />
		<div class="mcontainer">
			<h1 class="title page-title">{{ itemsName.mult }}</h1>
			<div class="view-content-card card content-row">
				<div class="card-content">
					<Filterbar
						:datepicker="true"
						:items-loading="ordersLoading"
						:filters="filters"
						:status-filter="true"
						:status-filter-list="orderStatusesList"
						@handleDateRange="handleDateRange"
						@setFilters="setFilters"
					/>
					<!-- :status-filter-prop="'order_status_id'" -->

					<OrdersTableContainer
						ref="ItemsTableContainer"
						:items-loading="ordersLoading"
						:items-saving="ordersSaving"
						:table-data="ordersList"
						:table-settings="tableSettings"
						:items-name="itemsName"
						@event="handleEvent"
					/>

					<PaginationContainer
						:items-name="itemsName"
						:filters="filters"
						:meta="meta"
						@setFilters="setFilters"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { itemsDataMixin, eventHandler, dateRangeMixin } from '@/mixins';
// import { orderStatusesList } from '@/constants/global';

export default {
	components: {
		Filterbar: () => import('../../components/Filterbar.vue'),
		OrdersTableContainer: () => import('./OrdersTableContainer.vue'),
		PaginationContainer: () => import('../../components/PaginationContainer.vue')
	},
	mixins: [itemsDataMixin, eventHandler, dateRangeMixin],

	computed: {
		...mapState({
			globalFilters: state => state.global.globalFilters,

			meta: state => state.orders.fetchedMeta,
			filters: state => state.orders.filters,
			ordersLoading: state => state.orders.isLoading,
			ordersSaving: state => state.orders.isSaving,
			ordersList: state => state.orders.itemsList,
			orderStatusesList: state => state.order_statuses.itemsList
		}),

		// orderStatusesList: () => orderStatusesList,

		navbarSettings: () => ({
			showCreateButton: true,
			showDeleteButton: true,
			showFilter: true
		}),

		itemsName() {
			return {
				one: 'Заказ',
				mult: 'Заказы'
			};
		},

		tableSettings() {
			return {
				columns: [
					{ prop: 'id', label: 'Заказ №', sortable: true, width: 60 },
					{
						prop: 'created_at',
						label: 'Дата',
						sortable: true,
						meta: {
							prepareValue: { method: 'cleanDateString' }
						}
					}
					/*{
						prop: 'user.full_name',
						label: 'Клиент',
						sortable: true,
						meta: { emptyText: ' - ', sortBy: 'user_id' }
					}*/
				],
				operations: {
					edit: true,
					delete: true
				}
			};
		}
	},

	methods: {
		...mapActions({
			setup_navbar: 'setup_navbar',
			show_overlay: 'show_overlay',

			fetch_items: 'orders/fetch_orders',
			delete_item: 'orders/delete_order',

			set_items: 'orders/set_orders',
			set_filters: 'orders/set_orders_filters'
		}),
		refreshOrdersList() {
			this.fetchItems({ ...this.filters, ...this.globalFilters });
		}
	}
};
</script>
